import { ReactNode } from 'react';
import { Orientation } from '../../../types';
import { Icon } from '../Icons';

export enum TabsType {
  BOXED = 'BOXED',
  GHOST = 'GHOST',
  LIFTED = 'LIFTED',
}

export type Tab = {
  /** Unique identifier for the tab */
  id: string;
  /** Display title of the tab */
  title: string;
  /** Content of the tab. If undefined, the tab content should be handled externally. */
  content?: ReactNode;
  /** If true, the tab will be disabled and cannot be selected */
  disabled?: boolean;
  /** Optional icon to display alongside the tab title */
  icon?: Icon;
};

type BaseTabsProps = {
  /** The ID of the currently active tab. */
  activeTab: string;
  /** An array of Tab objects representing the tabs to be rendered. */
  tabs: Tab[];
  /** Function to be called when a tab is selected. */
  setActiveTab: (newActiveTab: string) => void;
  /** Custom CSS class name for the main tabs component container. */
  className?: string;
  /** The type of the tabs. */
  type: TabsType;
};

/**
 * Tabs props with orientation only applicable to non-lifted tabs.
 */
export type TabsProps =
  | (BaseTabsProps & { type: TabsType.LIFTED; orientation?: Orientation.HORIZONTAL })
  | (BaseTabsProps & { type: Exclude<TabsType, TabsType.LIFTED>; orientation?: Orientation });
