import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { Skeleton } from '../Skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table';
import { DataTableColumnDef, DataTableProps } from './DataTable.types';
import PaginationControls from './PaginationControls';

/** Generic DataTable component designed to handle different data types with configurable columns. */
function DataTable<TData>({
  activeRowIndex,
  columns,
  data,
  isLoading,
  paginationControls,
  onRowClick,
  onRowHover,
  onRowRightClick,
}: DataTableProps<TData>) {
  const [localPagination, setLocalPagination] = useState(paginationControls);

  useEffect(() => {
    if (!isLoading && paginationControls) {
      setLocalPagination(paginationControls);
    }
  }, [isLoading, paginationControls]);

  // Initialize the table with configuration for columns and data.
  const table = useReactTable({
    data,
    columns,
    manualPagination: true, // Manual "server-side" pagination
    getCoreRowModel: getCoreRowModel(), // Function to generate a row model from provided data.
    onPaginationChange: localPagination?.setPagination,
    state: {
      pagination: localPagination?.pagination,
    },
  });

  const handleRowClick = (e: React.MouseEvent<Element, MouseEvent>, index: number) => {
    if (onRowClick) {
      onRowClick(e, index);
      if (onRowHover) {
        onRowHover(undefined);
      }
    }
  };

  const handleRowHover = (hover: boolean, index: number) => {
    if (onRowHover) {
      onRowHover(hover ? index : undefined);
    }
  };

  const handleRowRightClick = (e: React.MouseEvent<Element, MouseEvent>, index: number) => {
    if (onRowRightClick) {
      onRowRightClick(e, index);
      if (onRowHover) {
        onRowHover(undefined);
      }
    }
  };

  // Renders table rows based on loading state and data availability.
  const renderRows = () => {
    if (isLoading) {
      return Array.from({ length: 3 }).map((_, index) => (
        <TableRow key={index}>
          <TableCell colSpan={columns.length}>
            <Skeleton />
          </TableCell>
        </TableRow>
      ));
    }

    const rows = table.getRowModel().rows;

    if (!rows.length) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} className="h-24">
            No results.
          </TableCell>
        </TableRow>
      );
    }

    return rows.map((row, index) => (
      <TableRow
        key={row.id}
        data-state={row.getIsSelected() && 'selected'}
        active={activeRowIndex === index}
        onClick={(e) => handleRowClick(e, index)}
        onHover={(hover) => handleRowHover(hover, index)}
        onRightClick={(e) => handleRowRightClick(e, index)}
      >
        {row.getVisibleCells().map((cell) => {
          const columnDef: DataTableColumnDef<TData> = cell.column.columnDef;
          return (
            <TableCell key={cell.id} style={{ width: columnDef.width }}>
              <div>{flexRender(columnDef.cell, cell.getContext())}</div>
            </TableCell>
          );
        })}
      </TableRow>
    ));
  };

  return (
    <div className="flex flex-col items-center gap-2">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnDef: DataTableColumnDef<TData> = header.column.columnDef;
                return (
                  <TableHead key={header.id} style={{ width: columnDef.width }}>
                    {header.isPlaceholder ? null : flexRender(columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>{renderRows()}</TableBody>
      </Table>
      {!!localPagination && localPagination.totalPages > 1 && (
        <PaginationControls
          onPrevClick={() => table.previousPage()}
          onNextClick={() => table.nextPage()}
          pagination={localPagination.pagination}
          setPagination={localPagination.setPagination}
          totalPages={localPagination.totalPages}
        />
      )}
    </div>
  );
}

export default DataTable;
