import { SerializedError } from '@reduxjs/toolkit';
import { DBUser, OrgMember, Roles } from './../types';

export interface CustomFetchBaseQueryError {
  data: { message: string };
  status: number | string;
}

export type ApiPostError = CustomFetchBaseQueryError | SerializedError;

export type ApiPostSuccess = { message: string };

export type ApiPostResponse = {
  data?: ApiPostSuccess;
  error?: ApiPostError;
};

export enum ApiTagTypes {
  CALLS = 'Calls',
  CANDIDATES = 'Candidates',
  COMMENTS = 'Comments',
  INVITES = 'Invites',
  PHONE_NUMBERS = 'PhoneNumbers',
  TAGS = 'Tags',
  USERS = 'Users',
  SCORECARD_TEMPLATES = 'ScorecardTemplates',
  SCORECARD_TEMPLATES_FOR_SELECT = 'ScorecardTemplatesForSelect',
  SCORECARDS = 'Scorecards',
}

export type Invite = {
  id: string;
  email: string;
  role: Roles;
  invitationURL: string;
  expiresAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

export interface InvitesResponse {
  data: {
    invitations: Invite[];
  };
}

/** Defines the structure of a response from the server containing a list of users. */
export interface OrgUsersResponse {
  data: {
    users: OrgMember[];
  };
}

export type Pagination = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  pageSize: number;
};

/** Defines the structure of a response from the server containing a list of phone numbers. */
export interface PhoneNumbersResponse {
  data: {
    phoneNumbers: string[];
  };
}

export type UsersSelectResponse = {
  data: {
    phoneNumbers?: string[];
    users: DBUser[];
  };
};

export type InitiateCallResponseData = {
  callSid: string;
  verificationCode: string;
};

export type InitiateCallResponse = {
  data: InitiateCallResponseData;
  success: boolean;
  message: string;
};

export type GenerateUploadUrlResponseData = {
  presignedUrl: string;
  key: string;
};

export type GenerateUploadUrlPayload = {
  name: string;
  type: string;
  size: number;
};
