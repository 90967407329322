import * as RadixTabs from '@radix-ui/react-tabs';

import clsx from 'clsx';
import { TextColor } from '../../../types';
import { Icon, Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { Tab, TabsType } from './Tabs.types';

interface TabsTriggerProps {
  activeTab: string;
  tab: Tab;
  type: TabsType;
}

const TabsTrigger = ({ activeTab, tab, type }: TabsTriggerProps) => {
  const { id, title, disabled = false, icon: iconProp } = tab;

  const isActive = activeTab === id;
  const icon = disabled ? Icon.LOCK : iconProp;

  const textColor = isActive || type !== TabsType.BOXED ? TextColor.PRIMARY : TextColor.SECONDARY;

  const getBgColor = () => {
    if (!isActive) return 'hover:bg-base-200';
    switch (type) {
      case TabsType.BOXED:
        return 'bg-base-0';
      case TabsType.GHOST:
        return 'bg-base-300';
      case TabsType.LIFTED:
      default:
        return '';
    }
  };

  const renderTrigger = () => (
    <RadixTabs.Trigger
      key={id}
      value={id}
      className={clsx(
        'flex max-h-fit cursor-pointer items-center justify-center gap-2 rounded-lg px-4 py-1',
        disabled && 'pointer-events-none',
        getBgColor()
      )}
    >
      {icon && <Icons icon={icon} color={textColor} />}
      <Typography size={TypographySize.H5} color={textColor} noWrap>
        {title}
      </Typography>
    </RadixTabs.Trigger>
  );

  if (type === TabsType.LIFTED) {
    return (
      <div
        className={clsx(
          'z-10 flex h-12 items-center border border-transparent',
          !isActive && 'rounded-lg',
          isActive && 'rounded-t-lg border-l-base-100 border-r-base-100 border-t-base-100 bg-base-0'
        )}
      >
        {renderTrigger()}
      </div>
    );
  }

  return renderTrigger();
};

export default TabsTrigger;
