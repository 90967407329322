import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes, SIDEBAR_WIDTH } from '../../constants';
import { useFilterOptionsAndSelections, useGetFiltersFromParams } from '../../hooks';
import CreateProspectModal from '../modals/CreateProspectModal/CreateProspectModal';
import { ButtonColor, Divider, Icon, TextButton, Typography, TypographySize, TypographyWeight } from '../shared';
import CallHistoryFilters from './CallHistoryFilters';
import ClearFiltersDialog from './ClearFiltersDialog';
import PracticeFilters from './PracticeFilters';
import AnalyticsFilters from './AnalyticsFilters';
import { AnalyticsFilterKeys, AnalyticsFilters as AnalyticsFiltersType, DateOptions } from '../../types';

const SidebarContent = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCreateProspectModalOpen, setIsCreateProspectModalOpen] = useState(false);

  const location = useLocation();
  const isPracticePage = location.pathname.includes(AppRoutes.PRACTICE);
  const isCallHistoryPage = location.pathname.includes(AppRoutes.CALL_HISTORY);
  const isAnalyticsPage = location.pathname.includes(AppRoutes.ANALYTICS);

  const filters = useGetFiltersFromParams();
  const hasFilters = Object.keys(filters).length > 0;
  const analyticsFilters = filters as AnalyticsFiltersType;

  // Check if the analytics date range is only this month.
  // If it is, we don't want to show the clear filters button.
  // This is because the date range filter should not be cleared.
  const isAnalyticsDateRangeThisMonth =
    isAnalyticsPage &&
    analyticsFilters[AnalyticsFilterKeys.DATE_RANGE]?.length === 1 &&
    analyticsFilters[AnalyticsFilterKeys.DATE_RANGE]?.[0] === DateOptions.THIS_MONTH;

  const showClearFiltersButton = hasFilters && !isAnalyticsDateRangeThisMonth;

  const { accounts, prospects, tags } = useFilterOptionsAndSelections();

  return (
    <>
      <div
        className="flex h-full w-full flex-col gap-4 overflow-hidden border-r border-base-100 p-4"
        style={{ width: SIDEBAR_WIDTH }}
      >
        <div className="flex flex-col gap-4">
          <TextButton
            text="Create"
            onClick={() => setIsCreateProspectModalOpen(true)}
            fullWidth
            startIcon={Icon.WAND}
          />
          <Divider />
        </div>

        <Typography size={TypographySize.H4} weight={TypographyWeight.SEMI_BOLD} className="sticky top-0">
          Filters
        </Typography>
        <div className="flex flex-1 flex-col gap-6 overflow-y-auto pb-6">
          {isPracticePage && (
            <PracticeFilters
              accountOptionsAndSelections={accounts}
              prospectOptionsAndSelections={prospects}
              tagOptionsAndSelections={tags}
            />
          )}
          {isCallHistoryPage && (
            <CallHistoryFilters prospectOptionsAndSelections={prospects} tagOptionsAndSelections={tags} />
          )}
          {isAnalyticsPage && <AnalyticsFilters tagOptionsAndSelections={tags} />}
        </div>
        {showClearFiltersButton && (
          <TextButton
            text="Clear filters"
            onClick={() => setIsConfirmModalOpen(true)}
            fullWidth
            color={ButtonColor.SECONDARY}
          />
        )}
      </div>
      <ClearFiltersDialog isOpen={isConfirmModalOpen} setIsOpen={setIsConfirmModalOpen} />
      <CreateProspectModal isOpen={isCreateProspectModalOpen} onClose={() => setIsCreateProspectModalOpen(false)} />
    </>
  );
};

export default SidebarContent;
