import { useEffect, useMemo } from 'react';
import { FilterOptionsAndSelections, useGetFiltersFromParams, useUpdateFilterParams } from '../../hooks';
import { useGetUsersForSelectQuery } from '../../services';
import { AnalyticsFilterKeys, AnalyticsFilters as AnalyticsFiltersType, DateOptions } from '../../types';
import { getCallerOptions, getSelectedOptions, parseOptionalSelectOption } from '../../utils';
import { SidebarDateFilter, SidebarSelectFilter } from './SidebarFilterFields';
import { DEFAULT_ANALYTICS_DATE_RANGE } from '../../constants';

interface AnalyticsFiltersProps {
  tagOptionsAndSelections: FilterOptionsAndSelections;
}

const AnalyticsFilters = ({ tagOptionsAndSelections }: AnalyticsFiltersProps) => {
  // Retrieve filters from URL parameters.
  const filters: AnalyticsFiltersType = useGetFiltersFromParams();

  // Validate date range to ensure date range filter is always set.
  const validateDateRange = (dateRange: string[] | undefined) =>
    dateRange?.length ? dateRange : DEFAULT_ANALYTICS_DATE_RANGE;

  useEffect(() => {
    // Only update if there's no date range
    if (!filters.dateRange?.length) {
      updateFilterParams(AnalyticsFilterKeys.DATE_RANGE, DEFAULT_ANALYTICS_DATE_RANGE);
    }
  }, []);

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  // Fetch users and phone numbers for the caller options.
  const { data: userData, isLoading: isLoadingUsers } = useGetUsersForSelectQuery();

  // Memoize caller and prospect options to avoid unnecessary recalculations.
  const callerOptions = useMemo(() => getCallerOptions(userData), [userData]);

  // Find the selected caller option based on the current filters.
  const selectedCallers = useMemo(
    () => getSelectedOptions(callerOptions, filters.caller),
    [callerOptions, filters.caller]
  );

  // Check if the filters are clearable.
  // If the only filter is the date range and it's the default value, don't show the clear button.
  const isFiltersClearable = !(filters.dateRange?.length === 1 && filters.dateRange[0] === DateOptions.THIS_MONTH);

  return (
    <>
      {/* Call Date filter */}
      <SidebarDateFilter
        clearable={isFiltersClearable}
        filterKey={AnalyticsFilterKeys.DATE_RANGE}
        title="Date range"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.DATE_RANGE, validateDateRange(newValue))}
        selected={parseOptionalSelectOption(filters.dateRange)}
      />
      {/* Caller filter */}
      <SidebarSelectFilter
        options={callerOptions}
        placeholder="Select caller"
        selected={selectedCallers}
        title="Caller"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.CALLER, newValue)}
        loading={isLoadingUsers}
      />

      {/* Tags filter */}
      <SidebarSelectFilter
        title="Tags"
        placeholder="Select tag"
        onChange={(newValue) => updateFilterParams(AnalyticsFilterKeys.TAGS, newValue)}
        {...tagOptionsAndSelections}
      />
    </>
  );
};

export default AnalyticsFilters;
