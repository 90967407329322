import { BaseColor, StatusColor, TagColor } from '../types';

// --BASE COLORS--

/** Maps base colors to their corresponding background color classes */
export const BASE_COLOR_TO_BG_COLOR: Record<BaseColor, string> = {
  [BaseColor.NEUTRAL]: 'bg-neutral',
  [BaseColor.NEUTRAL_CONTENT]: 'bg-neutral-content',
  [BaseColor.BASE_100]: 'bg-base-100',
  [BaseColor.BASE_200]: 'bg-base-200',
  [BaseColor.BASE_300]: 'bg-base-300',
};

// --TAG COLORS--

/** Maps tag colors to their corresponding background color classes */
export const TAG_COLOR_TO_BG_COLOR: Record<TagColor, string> = {
  [TagColor.BLUE]: 'bg-tags-blue',
  [TagColor.INDIGO]: 'bg-tags-indigo',
  [TagColor.LIME]: 'bg-tags-lime',
  [TagColor.ORANGE]: 'bg-tags-orange',
  [TagColor.PINK]: 'bg-tags-pink',
  [TagColor.PURPLE]: 'bg-tags-purple',
  [TagColor.RED]: 'bg-tags-red',
  [TagColor.TEAL]: 'bg-tags-teal',
  [TagColor.YELLOW]: 'bg-tags-yellow',
};

// --STATUS COLORS--

/** Maps status colors to their corresponding text color classes */
export const STATUS_COLOR_TO_TEXT_COLOR: Record<StatusColor, string> = {
  [StatusColor.ERROR]: 'text-error',
  [StatusColor.INFO]: 'text-info',
  [StatusColor.SUCCESS]: 'text-success',
  [StatusColor.WARNING]: 'text-warning',
};

/** Maps status colors to their corresponding background color classes */
export const STATUS_COLOR_TO_BG_COLOR: Record<StatusColor, string> = {
  [StatusColor.ERROR]: 'bg-error',
  [StatusColor.INFO]: 'bg-info',
  [StatusColor.SUCCESS]: 'bg-success',
  [StatusColor.WARNING]: 'bg-warning',
};
