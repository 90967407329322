import { useEffect, useState } from 'react';
import { PRIVACY_POLICY_URL } from '../../../constants';
import { TextColor } from '../../../types';
import { Dialog, DialogType, Tabs, TabsType, Typography, TypographySize } from '../../shared';
import { AITwinTab, CustomProspectTab, ScorecardTab } from './CreateProspectTabs';

enum CreateProspectTab {
  CUSTOM_PROSPECT = 'CUSTOM_PROSPECT',
  AI_TWIN = 'AI_TWIN',
  SCORECARD = 'SCORECARD',
}

interface CreateProspectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateProspectModal = ({ isOpen, onClose }: CreateProspectModalProps) => {
  const [activeTab, setActiveTab] = useState(CreateProspectTab.CUSTOM_PROSPECT);

  // Reset active tab on closing the modal.
  useEffect(() => {
    if (!isOpen && activeTab !== CreateProspectTab.CUSTOM_PROSPECT) {
      setActiveTab(CreateProspectTab.CUSTOM_PROSPECT);
    }
  }, [isOpen]);

  return (
    <Dialog
      title="Create"
      isOpen={isOpen}
      onClose={onClose}
      type={DialogType.SETTINGS}
      footer={
        <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
          What you upload and share is private to your workspace. It is used to create realistic Practice Prospects, we
          do not train AI models using this information.&nbsp;
          <span className="cursor-pointer underline" onClick={() => window.open(PRIVACY_POLICY_URL, '_blank')}>
            Privacy policy
          </span>
        </Typography>
      }
    >
      <Tabs
        activeTab={activeTab}
        tabs={[
          {
            id: CreateProspectTab.CUSTOM_PROSPECT,
            title: 'Custom prospect',
            content: <CustomProspectTab onClose={onClose} />,
          },
          {
            id: CreateProspectTab.AI_TWIN,
            title: 'AI twin',
            content: <AITwinTab />,
          },
          {
            id: CreateProspectTab.SCORECARD,
            title: 'Scorecard',
            content: <ScorecardTab onClose={onClose} />,
          },
        ]}
        setActiveTab={(newActiveTab) => setActiveTab(newActiveTab as CreateProspectTab)}
        type={TabsType.LIFTED}
      />
    </Dialog>
  );
};

export default CreateProspectModal;
