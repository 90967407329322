import { useAppDispatch } from '../../../../../hooks';
import { openScorecardForm } from '../../../../../redux/reducers/modalReducer';
import { TextColor } from '../../../../../types';
import { Icon, TextButton, Typography, TypographySize, TypographyWeight } from '../../../../shared';

interface ScorecardsTabProps {
  onClose: () => void;
}

const ScorecardTab = ({ onClose }: ScorecardsTabProps) => {
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-col gap-4 rounded-2xl bg-base-100 p-4">
      <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
        Create new scorecard
      </Typography>
      <div className="flex justify-between">
        <Typography color={TextColor.SECONDARY}>Build scorecards for evaluating mock calls.</Typography>
        <TextButton
          text="Create"
          startIcon={Icon.CLIPBOARD}
          onClick={() => {
            onClose();
            dispatch(openScorecardForm());
          }}
        />
      </div>
    </div>
  );
};

export default ScorecardTab;
