import { SelectOption } from '../components';
import { AppRoutes } from '../constants';
import { AnalyticsFilterKeys, AppUser, CallHistoryFilterKeys, DateOptions, DateRangeType, FilterKeys, PracticeFilterKeys } from '../types';
import { getStartAndEndDatesFromDateOption } from './date.utils';
import { formatDisplayedPhoneNumber, snakeCaseToLabel } from './string.utils';

/**
 * Parses a required value into a SelectOption shape (label and value).
 */
export const parseRequiredSelectOption = (value: string): SelectOption => ({ label: snakeCaseToLabel(value), value });

/**
 * Converts an optional array of strings into an array of SelectOption objects.
 * Each string is transformed into a SelectOption with a label (converted from snake case)
 * and a value. If the input is undefined, the function returns undefined.
 */
export const parseOptionalSelectOption = (value?: string[]): SelectOption[] | undefined => {
  if (!value) return undefined;
  return value.map((v) => ({ label: snakeCaseToLabel(v), value: v }));
};

/**
 * Finds the selected options from the options list based on the selected value.
 * If the selected value is not in the options list, it is ignored.
 */
export const getSelectedOptions = (
  options: { value: string; label: string; color?: string }[],
  selectedValue?: string[]
): SelectOption[] => {
  return options.filter((option) => selectedValue?.includes(option.value));
};

export const getDateFilter = (dateFilter?: DateOptions[]) => {
  if (!dateFilter) return undefined;
  return dateFilter
    .map((date) => getStartAndEndDatesFromDateOption(date))
    .filter((date): date is DateRangeType => !!date);
};

/**
 * Creates caller options from user data.
 */
export const getCallerOptions = (userData?: { users: AppUser[]; phoneNumbers?: string[] }) => {
  const mappedUsers = userData?.users.map(({ id, name = '' }) => ({ value: id, label: name })) || [];
  const mappedPhoneNumbers = (userData?.phoneNumbers || []).map((phoneNumber) => ({
    value: phoneNumber,
    label: formatDisplayedPhoneNumber(phoneNumber),
  }));
  return mappedUsers.concat(mappedPhoneNumbers);
};

/**
 * Gets the current app route from the location pathname.
 */
export const getCurrentAppRoute = (): string => {
  if (location.pathname.includes(AppRoutes.CALL_HISTORY)) {
    return AppRoutes.CALL_HISTORY;
  }
  if (location.pathname.includes(AppRoutes.ANALYTICS)) {
    return AppRoutes.ANALYTICS;
  }
  return AppRoutes.PRACTICE;
};

/**
 * Gets the valid filter keys for the current app route.
 */
export const getValidKeys = (): FilterKeys[] => {
  switch (getCurrentAppRoute()) {
    case AppRoutes.CALL_HISTORY:
      return Object.values(CallHistoryFilterKeys);
    case AppRoutes.ANALYTICS:
      return Object.values(AnalyticsFilterKeys);
    case AppRoutes.PRACTICE:
    default:
      return Object.values(PracticeFilterKeys);
  }
};
