export enum BarChartKeys {
  LABEL = 'label',
  VALUE = 'value',
}

export enum AxisType {
  CATEGORY = 'category',
  NUMBER = 'number',
}

export type BarChartDataPoint = {
  [BarChartKeys.LABEL]: string;
  [BarChartKeys.VALUE]: number;
};

export interface BarChartProps {
  /** The data to display in the bar chart */
  data: BarChartDataPoint[];
  /** The title of the bar chart */
  title?: string;
}
