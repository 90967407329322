import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Icon } from '../../components';
import { AppRoutes } from '../../constants';
import { useAppSelector, useFeatureFlag } from '../../hooks';
import {
  AnalyticsPage,
  CallHistoryPage,
  FinishSetupPage,
  LayoutPage,
  MessagePage,
  NotFoundPage,
  PracticePage,
} from '../../pages';
import { LD_FeatureFlags, Roles } from '../../types';
import ProtectedRoute from './ProtectedRoute';
import { conditionalArray } from '../../utils';

/** Provides router context to the application. */
const CustomRouterProvider: React.FC = () => {
  const isUnderConstruction = useFeatureFlag(LD_FeatureFlags.UNDER_CONSTRUCTION);
  const isSalesRep = useAppSelector((state) => state.auth.user?.role === Roles.SALES_REP);

  // Router configuration using pre-defined route constants.
  const router = createBrowserRouter([
    {
      // Protected route that renders the main layout if the user is authenticated.
      path: AppRoutes.HOME,
      element: (
        <ProtectedRoute>
          <LayoutPage />
        </ProtectedRoute>
      ),
      children: [
        {
          // Redirect root path to Practice page.
          path: AppRoutes.HOME,
          element: <Navigate to={AppRoutes.PRACTICE} replace />,
        },
        {
          // Route for the Practice page.
          path: AppRoutes.PRACTICE,
          element: <PracticePage />,
        },
        {
          // Route for the Call History page, with optional callSid parameter
          path: `${AppRoutes.CALL_HISTORY}/:callSid?`,
          element: <CallHistoryPage />,
        },
        ...conditionalArray(!isSalesRep, {
          // Route for the Analytics page if the user is not asales rep.
          path: AppRoutes.ANALYTICS,
          element: <AnalyticsPage />,
        }),
      ],
    },
    {
      path: AppRoutes.FINISH_SETUP,
      element: (
        <ProtectedRoute>
          <FinishSetupPage />
        </ProtectedRoute>
      ),
    },
    {
      // Route for handling 404 not found errors.
      path: AppRoutes.NOT_FOUND,
      element: <NotFoundPage />,
    },
  ]);

  if (isUnderConstruction) {
    return (
      <MessagePage
        icon={Icon.TRAFFIC_CONE}
        title="Routine maintenance"
        message="Building new features just for you! Please try again in a few hours."
      />
    );
  }

  return <RouterProvider router={router} />;
};

export default CustomRouterProvider;
