import { BarChart, BarChartDataPoint, Divider, Typography, TypographySize, TypographyWeight } from '../../components';

interface AnalyticsCardProps {
  title: string;
  value: number;
  chartTitle: string;
  data: BarChartDataPoint[];
}

export const AnalyticsCard = ({ title, value, chartTitle, data }: AnalyticsCardProps) => (
  <div className="flex flex-col rounded-md border">
    <div className="flex flex-col gap-1 p-4">
      <Typography size={TypographySize.H5}>{title}</Typography>
      <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H1}>
        {value}
      </Typography>
    </div>
    <Divider />
    <div className="p-4">
      <BarChart data={data} title={chartTitle} />
    </div>
  </div>
);
