import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ButtonColor, Icon, IconButton, Tabs, TabsType } from '../../../components';
import { AppRoutes } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setCurrentCallDetails, setIsCallDetailsDrawerOpen } from '../../../redux/reducers';
import { useGetCallQuery } from '../../../services';
import CallDetailsDrawerLayout from './CallDetailsDrawerLayout';
import CallDetailsTags from './CallDetailsTags';
import { CallScorecard } from './CallScorecard';
import SummaryTab from './SummaryTab';
import TranscriptTab from './TranscriptTab';

// Enum for tab identifiers
enum CallDetailsTabs {
  SUMMARY = 'SUMMARY',
  TRANSCRIPT = 'TRANSCRIPT',
}

const DEFAULT_ACTIVE_TAB = CallDetailsTabs.SUMMARY;

interface CallDetailsDrawerProps {
  mediaPlayerRef: React.RefObject<HTMLDivElement>;
}

const CallDetailsDrawer = ({ mediaPlayerRef }: CallDetailsDrawerProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Get the call SID from the URL parameters.
  const { callSid } = useParams();

  // State to manage the active tab.
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  const [isScorecardOpen, setIsScorecardOpen] = useState(false);

  // State to manage the mounted state of the drawer.
  const [isDrawerMounted, setIsDrawerMounted] = useState(false);

  // Get the current call SID from the Redux store.
  // Get the drawer open state from the Redux store.

  const { callSid: currentCallSid, isCallDetailsDrawerOpen: isDrawerOpen } =
    useAppSelector((state) => state.callHistory.currentCallDetails) || {};

  // Fetch call data using the call ID.
  const { data: call, error } = useGetCallQuery(callSid || '', {
    skip: !callSid,
  });

  // Dispatches action to seek the current call to a specific time.
  const seekAudio = useCallback(
    (startTime: number) => {
      if (callSid) {
        // Enable auto-playing the audio to provide a seamless experience for the user,
        // enabling them to immediately hear the context of the selected transcript time.
        dispatch(setCurrentCallDetails({ callSid, startTime, autoPlay: true, isCallDetailsDrawerOpen: true }));
      }
    },
    [dispatch, callSid]
  );

  // Define the tabs for the page.
  const tabs = [
    { id: CallDetailsTabs.SUMMARY, title: 'Summary' },
    { id: CallDetailsTabs.TRANSCRIPT, title: 'Transcript' },
  ];

  // Handles opening the media player if the call audio path exists
  // by setting the call SID in the Redux store.
  useEffect(() => {
    if (callSid && currentCallSid !== callSid && !!call?.audioPath) {
      dispatch(setCurrentCallDetails({ callSid: callSid as string }));
    }
  }, [callSid, currentCallSid, call?.audioPath, dispatch]);

  useEffect(() => {
    // Only open the drawer if the call exists or there is an error.
    // To avoid the drawer opening when the call is still being fetched and hence the animation is not played.
    if (callSid && (call || error)) {
      // Open the drawer
      dispatch(setCurrentCallDetails({ callSid, isCallDetailsDrawerOpen: true }));
      setIsDrawerMounted(true);
    }
  }, [dispatch, callSid, call, error]);

  // Function to go back to the Call History page
  const closeDrawer = useCallback(() => {
    setIsDrawerMounted(false);
    // Reset active tab to default
    setActiveTab(DEFAULT_ACTIVE_TAB);
    // Delay the navigation to allow the drawer to close properly with animation.
    setTimeout(() => {
      // Preserve existing search parameters
      const currentSearchParams = new URLSearchParams(window.location.search);
      // Navigate back while preserving existing search params
      navigate({
        pathname: AppRoutes.CALL_HISTORY,
        search: currentSearchParams.toString(),
      });
      dispatch(setIsCallDetailsDrawerOpen(false));
    }, 700);
  }, [navigate, dispatch]);

  // Close the drawer when the user navigates back to the Call History page.
  useEffect(() => {
    const handlePopState = () => {
      closeDrawer();
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [dispatch, closeDrawer]);

  // Return null if the drawer is not open
  // Needs to be before the other return statements
  if (!isDrawerOpen) return null;

  if (error) {
    return (
      <CallDetailsDrawerLayout isDrawerMounted={isDrawerMounted}>
        <div className="flex items-center gap-4 p-4">
          <IconButton icon={Icon.MINIMIZE} onClick={closeDrawer} tooltip="Close" color={ButtonColor.SECONDARY} />
          <p>Error: Invalid call ID</p>
        </div>
      </CallDetailsDrawerLayout>
    );
  }

  if (!call) return null;

  return (
    <CallDetailsDrawerLayout isDrawerMounted={isDrawerMounted} mediaPlayerRef={mediaPlayerRef}>
      <div className="flex h-full flex-col">
        <div className="flex justify-between gap-4 border-b border-b-neutral-200 bg-white p-4 shadow-sm">
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={(newActiveTab) => setActiveTab(newActiveTab as CallDetailsTabs)}
            className="!gap-4"
            type={TabsType.GHOST}
          />

          <div className="flex items-center gap-4 overflow-auto">
            <CallDetailsTags tags={call.practiceProspect.tags} />
            <IconButton
              icon={Icon.MINIMIZE}
              onClick={closeDrawer}
              tooltip={isDrawerMounted ? 'Close' : ''}
              color={ButtonColor.SECONDARY}
            />
          </div>
        </div>

        <div className="flex flex-grow overflow-hidden">
          <div className="display-scrollbar-lg flex-grow overflow-auto p-4">
            {activeTab === CallDetailsTabs.SUMMARY && <SummaryTab call={call} seekAudio={seekAudio} />}
            {activeTab === CallDetailsTabs.TRANSCRIPT && <TranscriptTab call={call} seekAudio={seekAudio} />}
          </div>

          <CallScorecard isScorecardOpen={isScorecardOpen} setIsScorecardOpen={setIsScorecardOpen} />
        </div>
      </div>
    </CallDetailsDrawerLayout>
  );
};

export default CallDetailsDrawer;
