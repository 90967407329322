import { Dispatch, SetStateAction } from 'react';
import { ComponentSize } from '../../../types';
import {
  ButtonGroup,
  ButtonGroupItem,
  Dialog,
  DialogType,
  Icon,
  IconComponent,
  Typography,
  TypographySize,
} from '../../shared';

interface ConfirmModalProps {
  buttonText: string;
  confirmText: string | React.ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  title: string;
  destructive?: boolean;
  icon?: Icon | IconComponent;
  isLoading?: boolean;
}

const ConfirmModal = ({
  buttonText,
  confirmText,
  isOpen,
  setIsOpen,
  onConfirm,
  title,
  destructive,
  icon,
  isLoading,
}: ConfirmModalProps) => {
  return (
    <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)} title={title} type={DialogType.CONFIRM} icon={icon}>
      <div className="flex flex-col gap-4">
        <Typography size={TypographySize.H5}>{confirmText}</Typography>
        <ButtonGroup loading={isLoading} size={ComponentSize.MEDIUM}>
          <ButtonGroupItem key="confirm" text={buttonText} onClick={onConfirm} destructive={destructive} />
          <ButtonGroupItem key="cancel" text="Cancel" onClick={() => setIsOpen(false)} />
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export default ConfirmModal;
